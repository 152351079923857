<template>
    <div class="editor-container">
        <div ref="toolbar" class="toolbar">
        </div>
        <div ref="editor" class="editor">

        </div>
    </div>
</template>
<script>
    import E from 'wangeditor'
    export default {
        props:['value','height','placeholder'],
        data(){
            return {
                editor:null,
                isChange: false,
            }
        },
        mounted(){
            this.editor = new E(this.$refs.toolbar,this.$refs.editor);
            let editor = this.editor;
            editor.config.menus = [
                'head',  // 标题
                'bold',  // 粗体
                'fontSize',  // 字号
                'fontName',  // 字体
                'underline',  // 下划线
                'foreColor',  // 文字颜色
                'backColor',  // 背景颜色
                'list',  // 列表
                'image',  // 插入图片
                'table',  // 表格
                'undo',  // 撤销
                'redo',  // 重复
            ];
            editor.config.uploadImgShowBase64 = true; // base 64 存储图片
            // editor.config.uploadImgServer = process.env.BASE_URL+'/api/uploadImage';
            editor.config.uploadFileName = "editorImage"; // 后端接受上传文件的参数名
            editor.config.showLinkImg = false // 隐藏网络上传图片
            editor.config.focus = false
            editor.config.zIndex = 100;
            editor.config.onchange = (html) => {
                //编辑器内容修改后，将编辑内容同步到父组件，实现双向绑定
                this.isChange = true;
                this.$emit('input',html)
            };
            editor.config.placeholder = this.placeholder || '';
            editor.create();
            if (this.value){
                editor.txt.html(this.value);
            }
        },
        watch:{
            value:function (newValue,oldValue) {
                // 解决输入第一个字时光标总是回到最初的问题
                if(!this.isChange){
                    this.editor.txt.html(newValue);
                }
                this.isChange= false;
            }
        }
    }
</script>
<style lang="less" scoped>
    .editor-container{
        border: 1px solid #dadada;
        border-radius: 5px;
        // overflow: hidden;
        .toolbar{
            height: 35px;
            // border-bottom: 1px solid #ccc;
            ///deep/ .w-e-menu{
            //    padding: 0 10px;
            //}
        }
        .editor{
            height: 250px;
            ///deep/ .w-e-text{
            //    overflow-y: auto;
            //}
        }
    }


</style>
